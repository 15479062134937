<template>
  <v-main id="layout">
    <v-app-bar class="not-print" :elevation="0" color="white">
      <v-toolbar-title class="not-print">
        {{ $route.meta.title }}
      </v-toolbar-title>
      <div v-if="hidePrintType">
        <v-chip-group @update:modelValue="setPrintType" v-model="printType" mandatory>
          <v-chip label size="small" value="thermal" filter color="primary">
            Térmica
          </v-chip>
          <v-chip label size="small" value="a4" filter color="secondary">
            A4
          </v-chip>
        </v-chip-group>
      </div>
      <div class="pa-4 not-print">
        <AppBtn color="primary" @click="handlePrint()">Imprimir</AppBtn>
      </div>
    </v-app-bar>
    <v-theme-provider theme="light" with-background>
      <div class="not-print d-flex justify-center mb-5" v-if="printType == 'thermal' && hideThermalSize">
        <div>
          <AppSelect style="width: 200px" @update:modelValue="setThermalSize" :items="items" v-model="thermalSize"
            label="Tamanho" density="compact" />
        </div>
      </div>
      <slot />
    </v-theme-provider>
  </v-main>
</template>

<script setup>
const appStore = useAppStore();
const route = useRoute();

const layoutOptions = computed(() => route.meta?.layoutOptions);

const hidePrintType = computed(
  () => layoutOptions.value?.hidePrintType != true || false
);

const hideThermalSize = computed(
  () => layoutOptions.value?.hideThermalSize != true || false
);

const { printType, thermalSize } = storeToRefs(appStore);

const setPrintType = (type) => {
  appStore.setPrintType(type);
};

const setThermalSize = (size) => {
  appStore.setThermalSize(size);
};

const handlePrint = () => {
  window.print();
};

const items = [
  { title: "80mm", value: "80mm" },
  { title: "58mm", value: "58mm" },
];

</script>


<style lang="scss">
#layout {
  @media print {
    padding: 0 !important;

    .not-print {
      display: none !important;
    }
  }
}
</style>